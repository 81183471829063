import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Route } from 'react-router-dom'
import moment from 'moment'
import { GTM_ENV } from 'Config/constants'

import LetterBox from './LetterBox/LetterBox'
import AdressPage from './AdressPage/AdressPage'
import SubscriptionPage from './SubscriptionPage/SubscriptionPage'
import ContractPage from './ContractPage/ContractPage'
import InvoicesPage from './InvoicesPage/InvoicesPage'
import PaymentMeansPage from './PaymentMeansPage/PaymentMeansPage'
import FormalitesPage from './FormalitesPage/FormalitesPage'
import ActualisationPage from './ActualisationPage/ActualisationPage'
import { isEmpty, get } from 'lodash'

import { openModal } from 'Actions/ui'
import { notify } from 'Actions/notifications'
import { setCurrentUser } from 'Actions/user'
import { getUser } from '../../api/user'
import initSatisMeter from 'Helpers/satis-meter'
import fetchWrapper from 'Helpers/fetch-wrapper'

import 'Ui/input.sass'

const companiesExcludedForAnnualOffer = GTM_ENV === 'staging'
  ? [16]
  : [13]

export const requiredFieldsForAMLPopup = [
  'ppe',
  'is_foreign_company',
  'activity',
  'has_expert',
]

class CustomerArea extends Component {
  componentDidMount() {
    const { history, dispatch, storeUserData } = this.props

    getUser(res => {
      console.log('getUser res --------------------------------->')
      console.log(res)
      if (isEmpty(res) || get(res, 'message') === 'Unauthorized') {
        dispatch(notify('info', 'Votre session a expiré.', 4000))
        history.push('/login')

        return
      }

      if (res.aml_total_points === 100
        || res.isBlockedForDuplicate
        || res.beneficiairesFilesSuspended) {
        dispatch(
          openModal('notification', {
            message:
              'Votre compte est en cours de validation.',
            onButtonClick: () => history.push('/'),
            unclosable: true,
          }),
        )

        return
      }

      if (res.has_pending_prepaid_company_creation) {
        fetchWrapper('/api/company-creation/current-user')
          .then(res => {
            const { companyCreation, type } = res
            const urlPrefix = type === 'microCreation' ? '/micro' : ''

            history.push(`${urlPrefix}/creation-entreprise-informations?company_creation_id=${companyCreation.id}`)
          })

        return
      }

      if (res.has_pending_prepaid_domiciliation && !res.expedition_city) {
        history.push('/inscription-prepaid/adresse')
        return
      }

      if (res.is_domiciliated_user) {
        history.push('/mon-compte/lettres')
      } else {
        storeUserData(res)
        history.push('/mon-compte/formalites')
        return
      }

      initSatisMeter(res)

      if (res.account_status === 'DESABONNE' || res.account_status === 'RADIE') {
        dispatch(
          openModal('notification', {
            message:
              'Votre compte est suspendu. Si vous pensez qu’il s’agit d’une erreur, merci de contacter le service client',
            onButtonClick: () => history.push('/'),
            unclosable: true,
          }),
        )

        return
      }

      if (res.hasScreeningAlert) {
        dispatch(
          openModal('notification', {
            message:
              'Votre compte est en cours de validation, merci de vous reconnecter ultérieurement.',
            onButtonClick: () => history.push('/login'),
            unclosable: true,
          }),
        )

        return
      }

      // TODO uncomment when we finish this feature - if we finish it one day, an other similar feature has been done
      // if (res.has_other_unpaid_account) {
      //   storeUserData(res)
      //   history.push('/mon-compte/paiement')

      //   dispatch(
      //     openModal('notification', {
      //       message:
      //         'Votre compte est bloqué. Merci d’adresser un email à contact@ma-domiciliation.fr',
      //       onButtonClick: () => history.push('/'),
      //       unclosable: true,
      //     }),
      //   )

      //   return
      // }

      switch (res.netheos_status) {
        // case of successful login
        case 'ATTENTE_ARCHIVAGE':
        case 'ACCEPTE':
        case 'ARCHIVE': {
          storeUserData(res)

          this.openNeededModals(res)

          break
        }
        case 'DOCUMENTS_A_VALIDER':
          dispatch(
            openModal('notification', {
              message:
                'Votre compte est en cours de validation. Vous recevrez un email dès que votre compte sera validé.',
              onButtonClick: () => history.push('/login'),
              unclosable: true,
            }),
          )
          break
        case 'ATTENTE_DOCUMENTS':
          storeUserData(res)
          history.push('/inscription/signature')
          dispatch(notify('info', 'Il reste quelques étapes pour finaliser votre dossier.', 5000))
          break
        case 'COMPTE_CREE':
          storeUserData(res)
          history.push('/inscription/contrat')
          dispatch(notify('info', 'Il reste quelques étapes pour finaliser votre dossier.', 5000))
          break
        default:
          storeUserData(res)
          dispatch(
            notify(
              'info',
              'Une erreur est survenue, merci de contacter le service client si cela persiste.',
              5000,
            ),
          )
          history.push('/login')
      }
    })
  }

  openNeededModals(user) {
    const { dispatch, history } = this.props

    if (user.yousignPendingSignatures.length) {
      dispatch(openModal('annualOfferSignature', {
        signature: user.yousignPendingSignatures[0],
        unclosable: true,
      }))
    }

    // this discount modal is desactivated for now
    // if (moment().isAfter(moment('04/12/2023 12:00', 'DD/MM/YYYY HH:mm'))
    //   && user.payment_interval === 'mensuel'
    //   && !companiesExcludedForAnnualOffer.includes(user.company.id)
    // ) {
    //   dispatch(openModal('blackFridayAnnualOffer'))
    // }

    if (!(user.payment_status === 'Actif')) {
      history.push('/mon-compte/paiement')

      dispatch(
        openModal('notification', {
          message:
            'Compte bloqué. Une facture est en attente de paiement. Merci de modifier votre moyen de paiement ou d’approvisionner votre compte en banque, afin que le paiement de votre abonnement puisse être effectué.',
          buttonText: 'Changer mon moyen de paiement',
        }),
      )

      return
    }

    if (user.needsActualisation) {
      history.push('/mon-compte/actualisation')
      return
    }

    if (user.pendingStripeInvoices.length) {
      dispatch(
        openModal('pendingInvoicesPaymentModal', {
          pendingStripeInvoices: user.pendingStripeInvoices,
          unclosable: true,
        }),
      )

      return
    }

    if (user.hasUnpaidColis) {
      dispatch(
        openModal('unpaidColis', {
          unclosable: true,
        }),
      )

      return
    }

    if (!user.attestation_comptable_signed) {
      dispatch(
        openModal('attestation', {
          unclosable: !!user.siren,
        }),
      )
    }

    if (user.missing_netheos_documents) {
      dispatch(
        openModal('notification', {
          message:
            'Documents manquants - Compte bloqué temporairement. Afin de débloquer le compte, merci de contacter notre support par email : contact@ma-domiciliation.fr',
          onButtonClick: () => history.push('/login'),
          unclosable: true,
        }),
      )
      return
    }

    if (user.inpiData && user.inpiData.social_denomination_score < .7) {
      dispatch(openModal('wrongSiren', { unclosable: true }))
    }

    const needsAmlStep3 = !user.beneficiairesFilesSuspended
        && user.beneficiairesNeedingJustif.length
        && user.juridic_form !== 'EI'
        && user.juridic_form !== 'Micro-entreprise'

    const isUnder3months = moment(user.created_at).isAfter(moment().subtract(3, 'month'))

    if (
      (requiredFieldsForAMLPopup.some(field => user[field] === null || user[field] === '')
      || (!user.siren && !user.rna)  
      || needsAmlStep3)
    ) {
      dispatch(
        openModal('aml', {
          unclosable: !isUnder3months && !user.no_siren_access,
        }),
      )
    }

    if (!(user.ppeOrigin || user.ppeOriginOther)
      && (user.aml_score === 4 || user.ppe)
    ) {
      dispatch(
        openModal('ppeOrigin', {
          unclosable: true,
        }),
      )
    }

    if (!user.choix_domiciliation
      && user.aml_score > 2) {
      dispatch(
        openModal('choixDomiciliation', {
          unclosable: true,
        }),
      )
    }

    if ((user.siren || user.rna) && !user.kbis_file && !user.pappers_pdf_file) {
      dispatch(
        openModal('kbis', {
          unclosable: !isUnder3months,
        }),
      )
    }

    if ((user.siren || user.rna)
      && !user.statuts_pdf_file
      && user.juridic_form !== 'EI'
      && user.juridic_form !== 'Micro-entreprise'
    ) {
      dispatch(
        openModal('statuts', {
          unclosable: !isUnder3months,
        }),
      )
    }
  }

  render() {
    const { match, currentUser } = this.props

    return (
      <div className="CustomerArea">
        {currentUser.is_domiciliated_user &&
          <>
            <Route path={`${match.url}/lettres`} component={LetterBox} />
            <Route path={`${match.url}/adresse`} component={AdressPage} />
            <Route path={`${match.url}/abonnement`} component={SubscriptionPage} />
            <Route path={`${match.url}/contrat`} component={ContractPage} />
            <Route path={`${match.url}/paiement`} component={PaymentMeansPage} />
          </>}
        <Route path={`${match.url}/factures`} component={InvoicesPage} />
        <Route path={`${match.url}/formalites`} component={FormalitesPage} />
        <Route path={`${match.url}/actualisation`} component={ActualisationPage} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

const mapDispatchToProps = dispatch => ({
  storeUserData: res => dispatch(setCurrentUser(res)),
  dispatch,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerArea))
